/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/bootswatch/dist/lumen/bootstrap.min.css';
@import '../node_modules/font-awesome/css/font-awesome.min.css';
@import '../node_modules/alertifyjs/build/css/alertify.min.css';
@import '../node_modules/alertifyjs/build/css/themes/bootstrap.min.css';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.alertify-notifier .ajs-message.ajs-success{
  background: rgba(20, 20, 217, 0.95);
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
  
}
.btn-success, .btn-success:hover, .btn-success:active, .btn-success:visited {
  background-color: #2020ff !important;
  border-color: #2020ff !important;
}

.tab-panel {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
  }
  
  .nav-tabs > li.open, .member-tabset > .nav-tabs > li:hover {
    border-bottom: 4px solid #b2bcf8;
  }
  
  .member-tabset > .nav-tabs > li.open > a, .member-tabset > .nav-tabs > li:hover > a {
    border: 0;
    background: none !important;
    color: #333333;
  }
  
  .member-tabset > .nav-tabs > li.open > a > i, .member-tabset > .nav-tabs > li:hover > a > i {
    color: #a6a6a6;
  }
  
  .member-tabset > .nav-tabs > li.open .dropdown-menu, .member-tabset > .nav-tabs > li:hover .dropdown-menu {
    margin-top: 0px;
  }
  
  .member-tabset > .nav-tabs > li.active {
    border-bottom: 4px solid rgb(31, 133, 201);
    position: relative;
  }
  
  .member-tabset > .nav-tabs > li.active > a {
    border: 0 !important;
    color: #333333;
  }
  
  .member-tabset > .nav-tabs > li.active > a > i {
    color: #404040;
  }
  
  .member-tabset > .tab-content {
    margin-top: -3px;
    background-color: #fff;
    border: 0;
    border-top: 1px solid #eee;
    padding: 15px 0;
  }
